import React  from 'react';
import Nav from './templates/Nav';
import Masthead from './templates/Masthead';
import Home from './Home';
import Pictures from './other/Pictures';
import Directory from './other/Directory';
import Bulletin from './bulletin/Bulletin';
import WebBulletin from './bulletin/WebBulletin';
import ShortCalendar from './calendar/ShortCalendar';
import SearchResults from './SearchResults';
import Calendar from "./calendar/Calendar";
import Page from "./Page";
import P404 from './utilities/P404';
import Footer from './templates/Footer';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import {Container} from 'reactstrap';

const Body = () => {

  return (
    <Container fluid>
      <Router>
        <Switch>
          <Route path="/web-bulletin" component={WebBulletin} />
          <Route render={(_props) => (
            <div className="view">
              <Nav />
              <Masthead />
              <div className="content">
                <Switch>
                  <Route exact path="/"   component={Home} />
                  <Route path="/pictures" component={Pictures} />
                  <Route path="/directory" component={Directory} />
                  <Route path="/bulletin" component={Bulletin} />
                  <Route path="/shortCalendar" component={ShortCalendar} />
                  <Route path="/calendar" component={Calendar} />
                  <Route path="/search-results" component={SearchResults} />
                  <Route path="/:page" render={props => (<Page {...props} />)} />
                  <Route component={P404} />
                </Switch>
              </div>
              <Footer router={_props}/>
            </div>
          )} />
        </Switch>
      </Router>
    </Container>
  )
};

export default Body
