import React  from 'react';
import ShortCalendar from './calendar/ShortCalendar';
import Announcements from './announcements/Announcements';

const Home = () => {

  // let logScrollEnabled = true;
  // let scrolled = false;
  // const SCROLL_DELAY = 2500;
  // const PAGE_LOAD_DELAY = 500;

  // useEffect(() => {
  //   if (!isMobile) {
  //     setTimeout(() => {
  //       window.onscroll = () => {
  //         if (!scrolled && logScrollEnabled) {
  //           console.log('scroll');
  //           scrolled = true;
  //         }
  //       }
  //     }, PAGE_LOAD_DELAY);
  //     setTimeout(() => {
  //       logScrollEnabled = false;
  //     }, SCROLL_DELAY + PAGE_LOAD_DELAY);
  //   }
  // }, [])
  return (
    <div className="row content-pane">
      {/*<div className="col-12 d-none d-md-inline d-lg-inline d-xl-inline carousel">*/}
      {/*  <Pictures />*/}
      {/*</div>*/}

       {/*Commented out for COVID-19 only*/}
      <div className="col-xl-4 col-lg-5 col-md-6 d-none d-md-inline d-lg-inline d-xl-inline">
        <ShortCalendar />
      </div>
      <div className="col-xl-8 col-lg-7 col-md-6">
        <Announcements />
      </div>
      <div className="col-xl-4 col-lg-5 col-md-6 d-md-none d-lg-none d-xl-none">
        <ShortCalendar />
      </div>

      {/* Used for COVID-19 only */}
      {/*<div className="col-12">*/}
      {/*  <Announcements />*/}
      {/*</div>*/}
    </div>
  )
}

export default Home;
