import React, { useState, useContext } from 'react';
import AnnouncementItem from "./AnnouncementItem";
import ItemsError from "../utilities/ItemsError";
import NoItems from "../utilities/NoItems";
import { List } from 'react-content-loader'
import { useDocumentDataOnce, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import format from 'date-fns/format';
import AppContext from "../../AppContext";
import filterAnnouncements from "../utilities/filterAnnouncements";
import sortItems from "../utilities/sortItems";

const Announcements = props => {

  const [date] = useState(new Date());
  const appContext = useContext(AppContext);
  const [metadata, metadataLoading/*, metadataError*/] = useDocumentDataOnce(appContext.db.doc('announcements'));
  let [announcements, isLoading, error] = useCollectionDataOnce(appContext.db
    .doc('announcements')
    .collection('items')
    .where('onAnnouncement', '==', true)
  );
  if (announcements) {
    announcements = filterAnnouncements(announcements, date);
    announcements = announcements.sort(sortItems);
  }

  if (metadataLoading || isLoading) {
    return <><List /><List /></>
  } else if (error) {
    ItemsError("announcements");
  } else {
    let items = announcements.filter(it => !props.homepage || it.locationPriority === 'announcements');
    // items = items.sort((a,b) => a.priority < b.priority ? -1 : 1);
    return (
      <div className="card">
        <div className="card-header text-center strong home-header"><h4 className="bold">ANNOUNCEMENTS</h4></div>
        <div className="card-body">
          {
            items.length === 0 ? NoItems('announcements') : <AnnouncementItem items={items} />
          }
        </div>
        <div className="card-footer">Last updated {format(new Date(metadata.lastUpdated), 'MMM d')}</div>
      </div>
    )
  }
}

export default Announcements;
