const compareByPriorityAndDate = (a,b) => {
  if (a.priority < b.priority) {
    return -1
  } else if (a.priority > b.priority) {
    return 1
  } else {
    return b.dateOn - a.dateOn;
  }
}

const sortItems = (a,b) => {
  // Sort items by "urgent", then "priority", then "date"
  // Temporary kluge because announcements originally had a 'bulletinUrgent'
  //   property and all the rest just have an 'urgent' property
  let aUrgent = a.urgent || false;
  let bUrgent = b.urgent || false;
  if (aUrgent === bUrgent) {
    return compareByPriorityAndDate(a,b);
  } else if (aUrgent) {
    return -1;
  } else if (bUrgent) {
    return 1;
  }
}

export default sortItems;
