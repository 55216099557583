import React, { useState } from 'react';
import Body from './pages/Body';
import { isMobile } from 'react-device-detect';
import AppContext from './AppContext'
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

const App = props => {

  const [isSmallScreen] = useState(window.screen.width < 768);
  const [system/*, loading, error*/] = useDocumentDataOnce(props.db.doc('system'));

  // // Log clicks to any <a> tag
  // const logLinkClick = evt => {
  //   if (evt.target.matches('a')) {
  //     let text = evt.target.text;
  //     let protocol = 'Unknown';
  //     switch (evt.target.protocol) {
  //       case 'http:':
  //       case 'https:':
  //         protocol = 'URL'
  //         break;
  //
  //       case 'mailto:':
  //         protocol = 'EMAIL';
  //         text = 'email not saved'
  //         break;
  //
  //       default:
  //         break;
  //     }
  //   }
  // };
  //
  // // Add listeners to the <a> tags to track clicks on links
  // useEffect(() => {
  //   document.addEventListener('click', logLinkClick)
  // }, []);

  return (
    <div>
      <div>
        <AppContext.Provider value={
          {db: props.db, analytics: props.analytics, isMobile, isSmallScreen, system, SCHOOL: props.SCHOOL}
        }>
          <Body />
        </AppContext.Provider>
      </div>
    </div>
  )
}

export default App;

