import CalendarRenderWeek from "./CalendarRenderWeek";
const R = require('ramda');

const CalendarRenderMonth = (dates, startingIdx, daysInMonth, isSmall, filterText="") => {

  let requiredWeekCount = Math.trunc((daysInMonth + (-startingIdx)) / 7) + 1;
  let weeks = R.range(0, requiredWeekCount);
  let currentIdx = startingIdx;

  return weeks.map(() => {
    let crw = CalendarRenderWeek(dates, currentIdx, daysInMonth, isSmall, filterText);
    currentIdx += 7;
    return crw;
  })
}

export default CalendarRenderMonth;
