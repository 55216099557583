import React from 'react';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import urgentIcon from '../../assets/alert.png';
import newIcon from '../../assets/new.png';

const DAYS_TO_SHOW_NEW = 2;

const BulletinItem = (group, idx, it) => {

  let today = new Date();
  let itemDate = new Date(it.dateOn);
  let showNew = Math.abs(differenceInBusinessDays(today, itemDate)) <= DAYS_TO_SHOW_NEW

  return (
    <div className="card-text bulletin-item" key={group + idx}>
      {
        it.urgent ? <img className='bulletin-icon' alt="Urgent bulletin item" src={urgentIcon} /> : null
      }
      {
        showNew ? <img className='bulletin-icon' alt="New bulletin item" src={newIcon} /> : null
      }
      <span className="bulletin-tease">{it.tease.toUpperCase()}</span>{':  '}
      <span dangerouslySetInnerHTML={{__html: it.itemText}}></span>
      <span className="bulletin-contact">{' '}Contact: <a href={'mailto:' + it.contactEmail}>{it.contact}</a>
        {
          !it.alwaysOn ?
            <>
              {'  '}
              {it.dateOnStr} - {it.dateOffStr}
            </> : null
        }
        </span>
    </div>
  )
}

export default BulletinItem;
