// DO NOT DELETE OR MODIFY THIS FILE!!
// This file is a symbolic link to /phhs-website/constants.js so that
// the SCHOOL constant can be shared across all of the applications
// in this project.  You should modify this file only -- do not touch
// any of the symbolic links in the individual project applications!

const CONSTANTS = {
  SCHOOL: 'phhs',
  URL: 'https://patrickhenryhs.net'
}

module.exports = CONSTANTS;
