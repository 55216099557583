import React, { useState, useContext, useEffect } from 'react';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
// import { Input } from 'reactstrap';
import { List } from 'react-content-loader'
import getDay from 'date-fns/getDay';
import getDate from 'date-fns/getDate';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import CalendarRenderMonth from "./CalendarRenderMonth";
import AppContext from "../../AppContext";
const R = require('ramda');

const capitalizeWord = word => R.head(word).toUpperCase() + R.tail(word);

const tz = 'America/Los_Angeles';

const Calendar = () => {

  const TIME_ZONE = 'America/Los_Angeles';

  let appContext = useContext(AppContext);
  let [date, setDate] = useState(new Date());
  let [events, setEvents] = useState(null);
  let [isLoading, setIsLoading] = useState(true);
  let [isError, setIsError] = useState(false);
  let [dow, setDow] = useState(getDay(startOfMonth(date)));
  let [startingIdx, setStartingIdx] = useState(1 - dow);
  let [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(date));
  let [yearStr, setYearStr] = useState(format(date,'YYY'));
  let [monthStr, setMonthStr] = useState(format(date, 'MMMM'));
  let [isSmall] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setDow(getDay(startOfMonth(date)));
    setDaysInMonth(getDaysInMonth(date));
    setYearStr(format(date, 'yyy'));
    setMonthStr(format(date, 'MMMM'));
  }, [date]);

  useEffect(() => {
    setStartingIdx(1-dow);
  }, [dow]);

  useEffect(() => {
    // const justify = min => min < 10 ? '0'+min : min;
    // const findTimeStr = event => {
    //   return event.date ? format(new Date(event.date*1000), 'h:mm a') :
    //     ((event.hr !== null && event.min !== null) ?
    //       event.hr+':'+justify(event.min) : '');
    // };
    let firstMonth = startOfMonth(date).getTime()/1000;
    // let url = 'http://localhost:5000/'+appContext.SCHOOL+'/events/' + parseInt(firstMonth,10) +'/'+ getDaysInMonth(date);
    let url = 'https://us-central1-phhs-website.cloudfunctions.net/get/'+appContext.SCHOOL+'/events/' + parseInt(firstMonth,10) +'/'+ getDaysInMonth(date) + '?timezone=' + TIME_ZONE;
    fetch(url)
      .then(res => {
        return res.json()
      })
      .then(obj => {
        obj.typeList = obj.typeList.map(it => capitalizeWord(it)).sort((a,b) => a < b ? -1 : 1);
        obj.events = obj.events.map(event => {
          let date = new Date(event.date*1000);
          date = utcToZonedTime(date, tz);
          event.timeStr = event.ath ? ( event.hasTime ? format(date, 'h:mm a', {timeZone: tz}) : '') : event.tba ? '' : format(date, 'h:mm a', {timeZone: tz});
          return event;
        })
        obj.events = obj.events.filter(event => !event.ath);
        setEvents(obj.events);
        setIsError(obj.error);
        setIsLoading(false);
      })
      .catch(err => console.log({error: true, err}))
  }, [date, appContext.SCHOOL]);

  // Move the events into an array where the index is the day of the month
  const pigeonHoleEvents = (events, date) => {
    let calendarList = R.range(0, getDaysInMonth(date)+1).map(day => []);
    calendarList  = events.reduce((list, event) => {
      let dateObj = event.date ?
        new Date(event.date*1000) :
        new Date(event.year, event.month-1, event.day);
      let dayOfMonth = getDate(dateObj);
      list[dayOfMonth].push(event);
      return list;
    }, calendarList)
    return calendarList;
  }

  let calendarList = null;
  if (!isLoading && !isError) {
    // calendarList = pigeonHoleEvents(_events, date);
    calendarList = pigeonHoleEvents(events, date);
  }

  const changeYear = delta => {
    setIsLoading(true);
    let newDate = addYears(date, delta > 0 ? 1 : -1);
    setDate(newDate);
  }

  const nextYear = () => {
    changeYear(1);
  }

  const lastYear = () => {
    changeYear(-1);
  }

  const changeMonth = delta => {
    setIsLoading(true);
    let newDate = addMonths(date, delta > 0 ? 1 : -1);
    setDate(newDate);
  }

  const nextMonth = () => {
    changeMonth(1);
  }

  const lastMonth = () => {
    changeMonth(-1);
  }

  return !isLoading && !isError && calendarList ?
    <div>
      <div className="row calendar-controls">
        <div className="col-md-3 col-sm-12 text-center">
          <a className="btn btn-primary btn-sm" href="https://phpatriots.net/calendar/" target="_blank" rel="noopener noreferrer">Go to Athletics</a>
          {/*<Input type="select" onChange={onChangeFilter} value={filterText}>*/}
          {/*  <option key="_filterBy" value="@@">-- No filter --</option>*/}
          {/*  <option key="_noAth" value="@no-ath">Non-athletic events only</option>*/}
          {/*  {*/}
          {/*    typeList.map(item => <option key={item} value={item}>{item}</option>)*/}
          {/*  }*/}
          {/*</Input>*/}
        </div>
        <div className="col-lg-1 col-md-1 col-2 text-center">
          <button className="btn btn-primary btn-sm btn-block" onClick={lastYear} disabled={isLoading}>&lt;&lt;</button>
        </div>
        <div className="col-lg-1 col-md-1 col-2 text-center">
          <button className="btn btn-primary btn-sm btn-block" onClick={lastMonth} disabled={isLoading}>&lt;</button>
        </div>
        <div className="col-lg-3 col-md-5 col-4 text-center">
          {
            isLoading ? null : <span className=''>{isSmall ? monthStr.substring(0,3) : monthStr} {yearStr}</span>
          }
        </div>
        <div className="col-lg-1 col-md-1 col-2 text-center">
          <button className="btn btn-primary btn-sm btn-block" onClick={nextMonth} disabled={isLoading}>&gt;</button>
        </div>
        <div className="col-lg-1 col-md-1 col-2 text-center">
          <button className="btn btn-primary btn-sm btn-block" onClick={nextYear} disabled={isLoading}>&gt;&gt;</button>
        </div>
      </div>
      <table className="table table-sm calendar">
        <thead className="thead-light">
        {
          isSmall ? null :
            <tr>
              <th className="text-center">Sunday</th>
              <th className="text-center">Monday</th>
              <th className="text-center">Tuesday</th>
              <th className="text-center">Wednesday</th>
              <th className="text-center">Thursday</th>
              <th className="text-center">Friday</th>
              <th className="text-center">Saturday</th>
            </tr>
        }
        </thead>
        <tbody>
        {
          isLoading ?
            <><List></List><List></List></> :
            CalendarRenderMonth(calendarList, startingIdx, daysInMonth, isSmall)
        }
        </tbody>
      </table>
    </div>
    :
    <><List></List><List></List></>

}

export default Calendar;
