import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import constants from '../../constants';
// import henryLogo from '../../assets/henry.png';
import headLeft from '../../assets/head150-left.svg';
import headRight from '../../assets/head150-right.svg';
import AppContext from '../../AppContext';

const Masthead = () => {

  const appContext = useContext(AppContext);

  const goHome = () => {
    document.location.href = constants.URL;
  }

  const getNavBar = () => (
    <nav className="navbar navbar-expand-lg navbar-dark masthead-navbar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
        <ul className="navbar-nav masthead-nav">
          <li className="nav-item d-lg-none">
            <Link to="/" className="nav-link">Home</Link>
          </li>
          <li className="nav-item d-lg-none">
            <Link to="/shortCalendar" className="nav-link">Upcoming events</Link>
          </li>
          <li className="nav-item d-lg-none">
            <Link to="/calendar" className="nav-link">Calendar</Link>
          </li>
          <li className="nav-item d-lg-none">
            <a href="https://phpatriots.net" className="nav-link">Athletics</a>
          </li>
          <li className="nav-item d-lg-none">
            <Link to="/bulletin" className="nav-link">Bulletin</Link>
          </li>
          <li className="nav-item d-lg-none">
            <a href="https://lop-phhs.firebaseapp.com" className="nav-link">LOP List</a>
          </li>
          <li className="nav-item d-lg-none">
            <a href="https://powerschool.sandi.net/public" className="nav-link" target="_blank" rel="noopener noreferrer">Grades</a>
          </li>
          <li className="nav-item">
            <Link to="/students" className="nav-link">Students</Link>
          </li>
          <li className="nav-item">
            <Link to="/parents" className="nav-link">Parents</Link>
          </li>
          <li className="nav-item">
            <a href="https://sites.google.com/sandi.net/phhs-counseling-department/home" className="nav-link" target="_blank" rel="noopener noreferrer">Counseling</a>
            {/*<Link to="/counseling" className="nav-link">Counseling</Link>*/}
          </li>
          {/*<li className="nav-item">*/}
          {/*  <a href="https://sites.google.com/sandi.net/henry-health-office/home" className="nav-link" target="_blank">Health</a>*/}
          {/*</li>*/}
          <li className="nav-item">
            <Link to="/pictures" className="nav-link">Pictures</Link>
          </li>
          <li className="nav-item">
            <Link to="/departments" className="nav-link">Departments</Link>
          </li>
          <li className="nav-item">
            <Link to="/directory" className="nav-link">Staff directory</Link>
          </li>
          <li className="nav-item">
            <Link to="/staff" className="nav-link">For staff</Link>
          </li>
          <li className="nav-item">
            <a href="https://patrickhenryhs.net/EDOnzyhOsNksFTvzHQxU" className="nav-link">Contact us</a>
            {/*<Link to="/contact" className="nav-link">Contact us</Link>*/}
          </li>
        </ul>
      </div>
    </nav>
  )

  return (
    <div className="row masthead green-bar">
      <div className="col-lg-2 col-sm-3 text-center d-none d-lg-block d-xl-block">
        <img src={headRight} alt="PHHS logo"/>
      </div>
      <div className="col-lg-2 col-sm-3 col-xs-5 text-center d-lg-none">
        {getNavBar()}
      </div>
      <div className="col-lg-8 col-sm-6 col-xs-7 text-center">
        <h1 className="school-name d-none d-lg-inline d-xl-inline" onClick={goHome}>
          {(appContext.system) ? appContext.system.information.SCHOOL_NAME : 'Welcome!'}
        </h1>
        <h1 className="school-name school-name-small d-lg-none" onClick={goHome}>
          {(appContext.system) ? appContext.system.information.SCHOOL_NAME : 'Welcome!'}
        </h1>
        <div className="d-none d-lg-block d-xl-block">
          {getNavBar()}
        </div>
      </div>
      <div className="col-lg-2 col-sm-3 text-center d-none d-lg-block d-xl-block">
        <img src={headLeft} alt="PHHS logo"/>
      </div>
    </div>
  )
}

export default Masthead;
