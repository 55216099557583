import React from 'react';

const P404 = ({ location }) => {
    return (
      <div className="row">
        <div className="col">
          <h4 className="p404">{location.pathname} is not the page you're looking for</h4>
        </div>
      </div>
    )
  }

export default P404;
