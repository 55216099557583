import React, { useState, useContext, useEffect } from 'react';
import format from 'date-fns/format';
import { useDocumentDataOnce, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { List } from 'react-content-loader'
import AppContext from "../AppContext";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import newIcon from "../assets/new.png";
import urgentIcon from "../assets/alert.png";
const R = require('ramda');

const Page = props => {

  const appContext = useContext(AppContext);

  const [expanded, setExpanded] = useState(null);

  const [metadata, metadataLoading, metadataError] = useDocumentDataOnce(appContext.db.doc(props.match.params.page));
  const [_links, linksLoading/*, linksError*/] = useCollectionDataOnce(appContext.db.doc(props.match.params.page).collection('links'));
  const [_items, itemsLoading/*, itemsError*/] = useCollectionDataOnce(appContext.db.doc(props.match.params.page).collection('items'));

  const maxHeight = 250;
  const today = new Date();
  const DAYS_TO_SHOW_NEW = 3;

  let items = _items ? _items.sort((a,b) => a.order + (a.urgent ? -1000 : 0) < b.order + (b.urgent ? -1000 : 0) ? -1 : 1) : null;
  let links = _links ? _links.sort((a,b) => a.order + (a.urgent ? -1000 : 0) < b.order + (b.urgent ? -1000 : 0) ? -1 : 1) : null;

  if (items) {
    let today = (new Date()).getTime();
    items = items.filter(item => item.alwaysOn || (item.dateOn <= today && today <= item.dateOff));
    // items = items.sort(sortItems);
  }

  let itemsLength = items && items.length ? items.length : null;
  useEffect(() => {
    if (items && items.length > 0) {
      let _expanded = R.range(0, items.length);
      _expanded = _expanded.map(idx => document.getElementById('card-text-'+idx));
      // _expanded = _expanded.map(el => false);
      _expanded = _expanded.map(el => maxHeight - el.clientHeight > 3 ? 'none' : 'collapse');
      _expanded = _expanded.map((mode, idx) => {
        return items[idx].noTruncate ? 'none' : mode
      });
      setExpanded(_expanded);
    }
  }, [itemsLength])

  const showMore = idx => {
    let _expanded = R.clone(expanded);
    if (_expanded[idx] === 'collapse') {
      _expanded[idx] = 'expanded'
    } else if (_expanded[idx] === 'expanded') {
      _expanded[idx] = 'collapse'
    }
    setExpanded(_expanded);
  }

  const renderItemCard = (item, idx) => {
    let itemDate = new Date(item.dateOn);
    item.noTruncate = (item.noTruncate === undefined) ? false : item.noTruncate;
    let showNew = Math.abs(differenceInBusinessDays(today, itemDate)) <= DAYS_TO_SHOW_NEW
    let showMoreHTML = <span className="card-text-show-more" onClick={() => showMore(idx)}>Show more...</span>;
    let showLessHTML = <span className="card-text-show-more" onClick={() => showMore(idx)}>Show less...</span>;
    let footerHTML = null;
    if (!item.noTruncate && expanded !== null) {
      if (expanded[idx] === 'collapse') {
        footerHTML = showMoreHTML;
      } else if (expanded[idx] === 'expanded') {
        footerHTML = showLessHTML;
      }
    }

    return (
      <div key={'item'+idx} className={expanded ? "card page-item" : "card page-item"}>
        <div className="card-header">
          {showNew ? <img src={newIcon} alt="New item" className="bulletin-icon" /> : null}
          {item.urgent || item.bulletinUrgent ? <img src={urgentIcon} alt="New item" className="bulletin-icon" /> : null}
          {item.header}</div>
        <div className="card-body">
          {
            item.title.length > 0 ? <div className="card-title">{item.title}</div> : null
          }
          {
            item.subtitle.length > 0 ? <div className="card-subtitle">{item.subtitle}</div> : null
          }
          <div className={!item.noTruncate && expanded && expanded[idx] === 'collapse' ? "card-text-brief" : "card-text"} id={'card-text-'+idx} dangerouslySetInnerHTML={{__html: item.text}}></div>
          {
            !item.noTruncate && expanded && expanded[idx] === 'collapse' ?
              <div className="fader"></div> : null
          }
        </div>
        <div className="card-footer">
          {footerHTML}
        </div>
      </div>
    )
  }

  if (metadataLoading || linksLoading || itemsLoading) {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <List /><List />
        </div>
        <div className="col-lg-9 col-md-8">
          <List /><List />
        </div>
      </div>
    )
  }

  if (!metadata) {
    return <h4 className="text-center error-404">Unable to retrieve the {props.match.params.page} page</h4>
  } else if (links.length === 0) {
    return (
      <div className="row">
        <div className="col">
          <div className="card page-item">
            <div className="card-header">
              <h4 className="text-center">{metadata.text}</h4>
            </div>
            <div className="card-body">
              {
                items.map((item, idx) => renderItemCard(item, idx))
              }
            </div>
          </div>
          {
            metadataError ? null :
              <div className="card-footer">Page last
                updated: {metadata ? format(new Date(metadata.lastUpdated), 'MMM d, yyyy') : ''}</div>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <div className="card quick-links">
            <div className="card-header">
              <h4 className="text-center">Quick links</h4>
            </div>
            <div className="card-body">
              <ul>
              {
                links.map((link, idx) =>
                  link.url.length > 0 ?
                    <li style={{marginLeft: link.indent*5}} className={'item-link-indent'} key={'link'+idx}>
                      <div className='item-link-wrapper'>
                        <a className="item-link" href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
                      </div>
                    </li> :
                    <li style={{marginLeft: link.indent*5}} className={'item-link-indent'} key={'link'+idx}>
                      <div className='item-link-wrapper'>
                        <p className='item-link item-link-divider'>{link.text}</p>
                      </div>
                    </li>
                )
              }
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-8">
          <div className={expanded ? "card page-item" : "card page-item"}>
            <div className="card-header">
              <h4 className="text-center">{metadata.text}</h4>
            </div>
            <div className="card-body">
              {
                items.map((item, idx) => renderItemCard(item, idx))
              }
            </div>
          </div>
          {
            metadataError ? null :
              <div className="card-footer">Page last
                updated: {metadata ? format(new Date(metadata.lastUpdated), 'MMM d, yyyy') : ''}</div>
          }
        </div>
      </div>
    )
  }
}

export default Page;
