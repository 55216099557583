import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AppContext from "../../AppContext";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import filterAnnouncements from "../utilities/filterAnnouncements";
const CONTENT_PCT = 88;
const CHARS_PER_WORD = 5;
const WORDS_PER_MINUTE = 1.5;
const DEFAULT_DELAY = 5;

const WebBulletin = () => {

  let appContext = useContext(AppContext);

  let [displayIdx, setDisplayIdx] = useState(0);
  let [seconds, setSeconds] = useState(0);
  let [isActive, setIsActive] = useState(true);
  let textRef = useRef(null);

  let [bulletin, isLoading/*, error*/] = useCollectionDataOnce(appContext.db
    .doc('announcements')
    .collection('items')
    .where('onBulletin', '==', true)
  );
  if (bulletin) {
    bulletin = filterAnnouncements(bulletin, new Date());
  }

  const next = () => {
    setDisplayIdx((displayIdx+1) % bulletin.length);
  }

  const previous = () => {
    setDisplayIdx(displayIdx-1 < 0 ? bulletin.length-1 : displayIdx-1);
  }

  const toggleActive = () => {
    setIsActive(!isActive);
  }

  useEffect(() => {
    let timeout = null;
    if (bulletin && isActive) {
      if (seconds > 0) {
        timeout = setTimeout(() => {
          setSeconds(seconds => seconds - 1);
        }, 1000);
      } else {
        let item = bulletin ? bulletin[displayIdx+1 % bulletin.length] : null;
        let delay = parseInt(item ? item.itemText.length / CHARS_PER_WORD / WORDS_PER_MINUTE : DEFAULT_DELAY, 10);
        setDisplayIdx((displayIdx+1) % bulletin.length);
        setSeconds(delay);
      }
    } else if (!isActive) {
      clearInterval(timeout);
    }
    return () => clearInterval(timeout);
  }, [isActive, seconds, bulletin, displayIdx]);

  useEffect(() => {
    let el = textRef.current;
    let target = window.innerHeight * CONTENT_PCT / 100;
    let currentFont = 0.75;
    let delta = 0.01;
    if (!el) return;
    el.setAttribute('style', 'font-size:'+currentFont+'em;');
    while (el.clientHeight < target) {
      currentFont += delta;
      el.setAttribute('style', 'font-size:'+currentFont+'em;');
    }
  }, [displayIdx])

  let item = bulletin ? bulletin[displayIdx] : null
  let length = bulletin ? bulletin.length : 0;
  return (
    <div className="">
      <div className="row">
        <div className="col-md-3">
          {
            (appContext.system && !isLoading) ?
              <p>
                <span className="bulletin-web-nav-button" onClick={previous}>Prev</span> | <span
                className="">Slide {displayIdx + 1} of {length}</span> | <span
                className="bulletin-web-nav-button" onClick={next}>Next</span>
              </p> :
              <p>Slides are loading</p>
          }
        </div>
        <div className="col-md-6 text-center">
          <p className="">{appContext.system ? appContext.system.information.SCHOOL_NAME.toUpperCase() : ''} DAILY BULLETIN</p>
        </div>
        <div className="col-md-3 text-right">
          <Link className="bulletin-web-nav-button" to="/">Webpage</Link>
          <span className="bulletin-web-nav-button" onClick={toggleActive}>{isActive ? 'Pause' : 'Resume'}</span>
          {' ('+seconds+' secs)'}
        </div>
      </div>
      <hr className=""/>
      <div className="row">
        <div className="col">
          {
            !isLoading ?
              <p id="text" ref={textRef} className="">{item.tease.toUpperCase() + ': ' + item.itemText.replace(/<[^>]*>?/gm, '').replace('&nbsp;', ' ')}
                {'  '}
                <span className="">(Contact: <a href={'mailto:' + item.contactEmail}>{item.contact}</a>{'  '} {item.dateOnStr} - {item.dateOffStr})</span>
              </p> :
              <h2 className="text-center">Slides are loading...</h2>
          }
        </div>
      </div>
    </div>
  )
}

export default WebBulletin;
