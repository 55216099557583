import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import useFetch from "react-fetch-hook";
import format from 'date-fns/format';

const Nav = () => {

  let [isSmall] = useState(window.innerWidth < 1200);

  const weatherUrl = "https://api.openweathermap.org/data/2.5/weather?zip=92119,us&APPID=73dce8a0c0d3cfebed27915a99f5e906&units=imperial";
  const {/*isLoading,*/ data} = useFetch(weatherUrl);
  let condition, temp, now
  if (data) {
    condition = data.weather[0].main;
    temp = Math.round(data.main.temp)+'°';
    now = format(new Date(), 'EEE MMM d yyyy');
  }

  return (
    <div className="row top-nav top-nav-font d-none d-lg-flex d-xl-flex">
      <div className="col-lg-3 text-left d-none d-lg-block d-xl-block top-nav-weather">
        {condition ? <p>{now} | {condition} {temp}</p> : null}
      </div>
      <div className="col-lg-6 col-md-7 text-center d-none d-lg-block d-xl-block top-navbar">
        <nav className="navbar navbar-expand-lg">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to='/' className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/calendar" className="nav-link">Calendar</Link>
            </li>
            <li className="navbar-nav">
              <a className="nav-link" href="https://phpatriots.net/">Athletics</a>
            </li>
            <li className="navbar-nav">
              <Link to="/bulletin" className="nav-link">Bulletin</Link>
            </li>
            <li className="navbar-nav">
              <a className="nav-link" href="https://lop-phhs-282317.firebaseapp.com/" target="_blank" rel="noopener noreferrer">{isSmall ? 'LOP' : 'LOP list'}</a>
            </li>
            <li className="navbar-nav">
              <a className="nav-link" href="https://powerschool.sandi.net/public" target="_blank" rel="noopener noreferrer">Grades</a>
            </li>
            <li className="navbar-nav">
              <a className="nav-link" href="https://clever.com/oauth/authorize?channel=clever&client_id=4c63c1cf623dce82caac&confirmed=true&redirect_uri=https%3A%2F%2Fclever.com%2Fin%2Fauth_callback&response_type=code&state=aaa95680034f31c71674105a3e574728b058a920de4ac847e67a51f7f8b7a8a8&target=%3BNGM2M2MxY2Y2MjNkY2U4MmNhYWM%3D%3BaHR0cHM6Ly9jbGV2ZXIuY29tL2luL2F1dGhfY2FsbGJhY2s%3D%3BYWFhOTU2ODAwMzRmMzFjNzE2NzQxMDVhM2U1NzQ3MjhiMDU4YTkyMGRlNGFjODQ3ZTY3YTUxZjdmOGI3YThhOA%3D%3D%3BY29kZQ%3D%3D%3B&district_id=5429984f3029bfcb7c00004e" target="_blank" rel="noopener noreferrer">Clever</a>
            </li>
            <li className="navbar-nav">
              <a className="nav-link" href="https://sites.google.com/sandi.net/phhs-health/home" target="_blank" rel="noopener noreferrer">Health</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col-lg-3 col-md-2 text-right d-none d-lg-block d-xl-block top-nav-gcs">
        <div className="gcse-searchbox-only" data-as_sitesearch="www.patrickhenryhs.net" data-resultsurl={window.location.href.split('/')[0]+'/search-results'}></div>
      </div>
    </div>
  )
}

export default Nav;
