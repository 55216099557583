import format from 'date-fns/format';
import addDays from 'date-fns/addDays';

const filterAnnouncements = (list, date) => {
  const dateTime = date.getTime();
  list = list.filter(it => {
    let dateOff = addDays(new Date(it.dateOff), 1);
    return it.alwaysOn || (it.dateOn <= dateTime && dateTime <= dateOff)
  });
  list = list.map(it => {
    it.dateOnStr = format(it.dateOn, 'MMM d');
    it.dateOffStr = format(it.dateOff, 'MMM d');
    return it;
  })
  return list
}

export default filterAnnouncements;
