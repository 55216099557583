import React, { useContext, useState } from 'react';
import AppContext from "../../AppContext";
import { List } from 'react-content-loader';
import useFetch from "react-fetch-hook";
import format from 'date-fns-tz/format';
import parseZoomLink from "../utilities/parseZoomLink";

const R = require('ramda');

const capitalizeWord = word => R.head(word).toUpperCase() + R.tail(word);

const ShortCalendarError = () => <div className="row"><h5 className="text-center announcement-no-events">There was a problem retrieving the calendar events</h5></div>

const tz = 'America/Los_Angeles';

const ShortCalendar = () => {

  let appContext = useContext(AppContext);

  // Get event data
  let [date] = useState(new Date());
  const SHORT_CALENDAR_DURATION = 7;
  const TIME_ZONE = 'America/Los_Angeles';
  let url = 'https://us-central1-phhs-website.cloudfunctions.net/get/'+appContext.SCHOOL+'/events/' + parseInt(date.getTime()/1000,10) +'/'+ SHORT_CALENDAR_DURATION + '?timezone=' + TIME_ZONE;
  const _events = useFetch(url);
  const events = {
    isLoading: _events.isLoading,
    err: _events.data ? _events.data.err : false,
    data: _events.data ? _events.data.events : null,
    lastUpdate: _events.data ? _events.data.lastUpdate : null
  }

  if (events.data)  {
    events.data = events.data.filter(it => !it.ath);
    events.data = events.data.map(it => {
      let date = new Date(it.date*1000);
      // date = utcToZonedTime(date, tz);
      it.dateStr = format(date, 'eeeeee MMM d', {timeZone: tz});
      it.timeStr = it.tba ? '' : format(date, 'h:mm a', {timeZone: tz});
      // it.timeStr = it.ath ? ( it.hasTime ? format(date, 'h:mm a') : '') : format(date, 'h:mm a');
      return it;
    })
    events.data = events.data.sort((a,b) => a.date < b.date ? -1 : 1);
  }

  if (events.data) {
    if (events.data && events.err) {
      return ShortCalendarError()
    } else {
      let dateStr = null;
      if (events.lastUpdate) {
        let luDate = new Date(events.lastUpdate);
        dateStr = 'Last updated '+format(luDate, 'MMM d ')+' at '+format(luDate, 'h:mm a');
      }
      let today = format(new Date(), 'eeeeee MMM d');
      return (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header text-center">
                <div className="row">
                  <div className="col">
                    <h4 className="bold">UPCOMING EVENTS</h4>
                    {
                      (events.data.length === 0) ?
                        <h6>Unable to retrieve one or more calendars</h6> : null
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6>
                      <a href="https://phpatriots.net/events/" target="_blank" rel="noreferrer noopener">Athletics calendar</a>
                    </h6>
                  </div>
                  <div className="col">
                    <h6>
                      <a href="calendar">Complete calendar</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="cardBody">
                <table className="table table-sm short-calendar">
                  <thead>
                  <tr className="">
                    <th className="short-calendar-date text-center">Date</th>
                    <th className="short-calendar-type text-center">Type</th>
                    <th className="short-calendar-description">Description</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    events.data.map((it, idx) => {
                      return (
                        <tr className={(today === it.dateStr ? 'short-calendar-today' : '')+' short-calendar-item '+it.type.toLowerCase()} type={it.type} key={'ev' + idx}>
                          <td className="text-center">
                            <p>{it.dateStr}</p>
                            <p>{it.timeStr}</p>
                          </td>
                          <td className="text-center">
                            <p className="">{capitalizeWord(it.type)}</p>
                          </td>
                          <td className="">
                            <p className="" dangerouslySetInnerHTML={{__html: parseZoomLink(it.description)+' '+parseZoomLink(it.location)+ ' ' + parseZoomLink(it.notes)}}></p>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer">{dateStr ? dateStr : null}</div>
            </div>
          </div>
        </div>
      )
    }
  } else {
    return <List />
  }
}

export default ShortCalendar;
