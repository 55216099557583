import React, {useState} from 'react';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import newIcon from '../../assets/new.png';
import urgentIcon from '../../assets/alert.png';

const DAYS_TO_SHOW_NEW = 2;
const R = require('ramda');

const AnnouncementItem = props => {

  const today = new Date();
  const [expanded, setExpanded] = useState(R.range(0, props.items.length).map(it => 'collapse'));

  const showMore = idx => {
    let _expanded = R.clone(expanded);
    if (_expanded[idx] === 'collapse') {
      _expanded[idx] = 'expanded'
    } else if (_expanded[idx] === 'expanded') {
      _expanded[idx] = 'collapse'
    }
    setExpanded(_expanded);
  }

  return (
    props.items.map((it, idx) => {
      let itemDate = new Date(it.dateOn);
      it.noTruncate = (it.noTruncate === undefined) ? false : it.noTruncate;
      let showNew = Math.abs(differenceInBusinessDays(today, itemDate)) <= DAYS_TO_SHOW_NEW
      let showMoreHTML = <span className="card-text-show-more" onClick={() => showMore(idx)}>Show more...</span>;
      let showLessHTML = <span className="card-text-show-more" onClick={() => showMore(idx)}>Show less...</span>;
      let footerHTML = null;
      if (!it.noTruncate && expanded !== null) {
        if (expanded[idx] === 'collapse') {
          footerHTML = showMoreHTML;
        } else if (expanded[idx] === 'expanded') {
          footerHTML = showLessHTML;
        }
      }
      return (
        it.onAnnouncement ?
          <div className="card" key={'mid' + idx}>
            <div className="card-header">
              {showNew ? <img src={newIcon} alt="New item" className="bulletin-icon" /> : null}
              {it.urgent || it.bulletinUrgent ? <img src={urgentIcon} alt="New item" className="bulletin-icon" /> : null}
              {it.tease}
            </div>
            <div className="card-body">
              <div className={!it.noTruncate && expanded && expanded[idx] === 'collapse' ? "card-text-brief" : "card-text"} id={'card-text-'+idx} dangerouslySetInnerHTML={{__html: it.itemText}}></div>
              {
                !it.noTruncate && expanded && expanded[idx] === 'collapse' ?
                  <div className="fader"></div> : null
              }
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col">
                  {footerHTML}
                </div>
                <div className="col text-center">
                  <a href={'mailto:' + it.contactEmail}>{it.contact}</a>
                </div>
                {
                  !it.alwaysOn ?
                    <div className="col text-right">
                      <span className="">{it.dateOnStr} - {it.dateOffStr}</span>
                    </div> :
                    <div className="col"></div>
                }
              </div>
            </div>
          </div> : null
        )
      }
    )
  )
}

export default AnnouncementItem;

