const parseZoomLink = (html) => {
  let zoomLinkPos = html.indexOf('>https://sandiegounified.zoom.us/j/');
  if (zoomLinkPos > -1) {
    let zoomLinkEndPos = html.indexOf('</a>');
    html = html.substring(0, zoomLinkPos+1) + 'Zoom link' + html.substring(zoomLinkEndPos);
  }
  let innerHtml = html
  return html
}

export default parseZoomLink;
