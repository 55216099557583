import React from 'react';
import parseZoomLink from "../utilities/parseZoomLink";
const R = require('ramda');

const capitalizeWord = word => R.head(word).toUpperCase() + R.tail(word);

const eventToString = (event, showType) => {
  let type = event.type && showType ? '('+capitalizeWord(event.type)+')' : '';
  return `${event.timeStr} ${parseZoomLink(event.description)} ${parseZoomLink(event.location)} ${parseZoomLink(event.notes)} ${type}`;
};

// dates: array of events; index is the date
// currentIdx: is the index in dates we should start with
// startDate: The starting date for Sunday
// daysInMonth: The last day of the month we're rendering
// Returns: the <td> element
const CalendarRenderWeek = (dates, currentIdx, daysInMonth, isSmall, filterText) => {
  let cells = R.range(0, 7);
  if (!isSmall) {
    cells = cells.map((cellNumber, idx) => {
      if (currentIdx + cellNumber <= 0 || currentIdx + cellNumber > daysInMonth) {
        return <td key={'cell' + (currentIdx + cellNumber)}></td>
      } else {
        let events = dates[currentIdx + cellNumber];
        return (
          <td key={'cell' + (currentIdx + cellNumber)}>
            <p className="calendar-date">-{currentIdx + cellNumber}-</p>
            {
              events.map((event, idx) =>
                <p key={'date' + (currentIdx + cellNumber) + ' ' + idx}
                   dangerouslySetInnerHTML={{__html: eventToString(event, filterText.indexOf('@') >= 0)}}
                   className={'calendar-item '+event.type.toLowerCase()}></p>)
            }
          </td>
        )
      }
    })
    return (<tr key={'week' + currentIdx}>{cells}</tr>)
  } else {
    cells = cells.map((cellNumber, idx) => {
        let events = dates[currentIdx + cellNumber];
        return (
          currentIdx + cellNumber > 0 ?
            <tr key={'cell' + (currentIdx + cellNumber)}>
              <td>
                <p className="calendar-date">-{currentIdx + cellNumber}-</p>
                {
                  events ?
                    events.map((event, idx) =>
                      <p key={'date' + (currentIdx + cellNumber) + ' ' + idx}
                         dangerouslySetInnerHTML={{__html: eventToString(event, filterText.indexOf('@') >= 0)}}
                         className={'calendar-item'+event.type.toLowerCase()}></p>)
                    : null
                }
              </td>
            </tr> : null
        )
      })
    return cells
  }
}

export default CalendarRenderWeek;
