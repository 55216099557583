import React from 'react';
import BulletinItem from './BulletinItem';
import sortItems from "../utilities/sortItems";

const BulletinGroup = groups => {
  return groups.map(group => {
    group.items = group.items.sort(sortItems);
    return (
      <div className="card" key={group.category}>
        <div className="card-header text-center bulletin-category">
          <h5>{group.category.toUpperCase()}</h5>
        </div>
        <div className="card-body">
          {
            group.items.map((it, idx) => {
              return BulletinItem(group.category, idx, it)
            })
          }
        </div>
      </div>
    )
  })
}

export default BulletinGroup;
