import React, { useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AppContext from "../../AppContext";
import kindness from "../../assets/kindness-certificate.png";
import npfh from "../../assets/npfh-logo.jpg";
const version = require('../../../package.json').version;

const Footer = props => {

  let appContext = useContext(AppContext);
  let [about, setAbout] = useState(false);

  let info = appContext && appContext.system ?
    appContext.system.information :
    null;

  const openAbout = () => {
    setAbout(true);
  }

  return (
    <div className="row footer green-bar d-none d-lg-flex d-xl-flex">
      <div className="col-lg-4 text-left">
        {
          info ?
            <>
              <h6>{info.SCHOOL_NAME}</h6>
              <h6>{info.SCHOOL_ADDRESS}</h6>
              <h6>{info.SCHOOL_CITY}</h6>
              <h6>{info.SCHOOL_PHONE}</h6>
              <h6 onClick={openAbout} className="email-link">Version {version}</h6>
            </> : null
        }
      </div>
      <div className="col-lg-4 text-center">
        <img width="90px" src={kindness} className="right-separator" alt="Kindness certificate" />
        <img width="90px" src={npfh} alt="No Place For Hate logo" />
        <h6 className="top-separator">
          <a href="https://www.sandiegounified.org/about/policies_procedures/nondiscrimination_statement" target="_blank" rel="noopener noreferrer">Non-discrimination Statement</a>
        </h6>
      </div>
      <div className="col-lg-4 text-right">
        {
          info ?
            <>
              <h6>Principal: {info.PRINCIPAL}</h6>
              <h6>Webmaster: <a className="email-link" href={'mailto:'+info.TECHNOLOGY_COORDINATOR_EMAIL}>{info.WEBMASTER}</a></h6>
              <h6>Technology Coordinator: <a className="email-link" href={'mailto:'+info.TECHNOLOGY_COORDINATOR_EMAIL}>{info.TECHNOLOGY_COORDINATOR}</a></h6>
              <h6>Copyright 2023 Roger Jaffe</h6>
              <h6>
                <a href="https://www.sandiegounified.org/departments/ada_section_504/accessibility_notice" target="_blank" rel="noopener noreferrer">Accessibility Notice</a>
              </h6>
              <h6><a href="https://manage.patrickhenryhs.net" target="_blank" rel="noopener noreferrer">Management login</a></h6>
              {
                props.router.location.pathname === '/calendar' ? <h6><a href="https://calendar.google.com/calendar/r/week" target="_blank" rel="noopener noreferrer">PHHS calendar login</a></h6> : null
              }
            </> : null
        }
      </div>
      <Modal isOpen={about}>
        <ModalHeader>Acknowledgments</ModalHeader>
        <ModalBody>
          <p>Thank you to the following software packages used on the webpage:</p>
          <ul>
            <li><a href="https://getbootstrap.com">Bootstrap</a></li>
            <li><a href="https://date-fns.org/">Date-fns</a></li>
            <li><a href="https://firebase.google.com/">Firebase</a></li>
            <li><a href="https://www.npmjs.com/package/node-fetch">Node fetch</a></li>
            <li><a href="https://reactjs.org/">React</a></li>
            <li><a href="https://www.npmjs.com/package/react-device-detect">React device detection</a></li>
            <li><a href="https://www.npmjs.com/package/react-fetch-hook">React fetch hook</a></li>
            <li><a href="https://www.npmjs.com/package/react-firebase-hooks">React Firebase hooks</a></li>
            <li><a href="https://github.com/facebook/create-react-app">React scripts</a></li>
            <li><a href="http://reactstrap.github.io/">Reactstrap</a></li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <button className="btn" type="button" onClick={() => setAbout(false)}>Close</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Footer;
