import React  from 'react';

const SearchResults = props => {

  return (
    <div className="row page">
      <div className="col">
        <div className="gcse-searchresults-only"></div>
      </div>
    </div>
  )
}

export default SearchResults;
