import React, { useState, useContext } from 'react';
import useFetch from "react-fetch-hook";
import {Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption} from 'reactstrap';
import { Instagram } from "react-content-loader";
import AppContext from "../../AppContext";

const Pictures = () => {

  const appContext = useContext(AppContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [root] = useState("https://storage.googleapis.com");
  const [url] = useState(`https://www.googleapis.com/storage/v1/b/phhs-website.appspot.com/o?delimiter=/&prefix=${appContext.SCHOOL}/pictures/`);

  let items = [];
  const {isLoading, data} = useFetch(url);
  console.log(data);
  if (data && data.items) {
    let now = (new Date()).getTime();
    items = data.items.filter(item => item.metadata.dateOff > now || item.metadata.alwaysOn === 'true');
    items = items.map((item, idx) => {
      return ({
        src: root + '/' + item.bucket + '/' + item.name,
        date: item.updated,
        altText: item.metadata && item.metadata.alt ? item.metadata.alt : 'Carousel picture',
        caption: item.metadata && item.metadata.caption ? item.metadata.caption : ''
      })
    }).sort((a, b) => a.date > b.date ? -1 : 1)
  }

  const onExiting = () => {
    setAnimating(true);
  }

  const onExited = () => {
    setAnimating(false);
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    // this.setState({ activeIndex: nextIndex });
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = isLoading ? {} : items.map(item => {
    return (
      <CarouselItem
        className="carousel-picture"
        onExiting={onExiting}
        onExited={onExited}
        key={item.src} >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} />
      </CarouselItem>
    )
  })

  return isLoading ? <Instagram /> : (
    items.length > 0 ?
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
          {slides}
        <CarouselControl direction="prev" directionText="Previous" className="carousel-control" onClickHandler={previous}/>
        <CarouselControl direction="next" directionText="Next" className="carousel-control" onClickHandler={next}/>
      </Carousel> :
      null
  )
}

export default Pictures
