import React, { useState, useContext } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label} from 'reactstrap';
import { Link } from 'react-router-dom';
import ItemsError from "../utilities/ItemsError";
import format from 'date-fns/format';
import { List } from 'react-content-loader'
import BulletinGroup from './BulletinGroup';
import AppContext from "../../AppContext";
import {useCollectionDataOnce, useDocumentDataOnce} from "react-firebase-hooks/firestore";
import filterAnnouncements from '../utilities/filterAnnouncements';

const R = require('ramda');

const Bulletin = props => {

  let appContext = useContext(AppContext);

  let [date, setDate] = useState(new Date());
  let [oldDate, setOldDate] = useState(new Date());
  let [dateError, setDateError] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [popupBlockedModal, setPopupBlockedModal] = useState(false);

  const [metadata/*, metadataLoading, metadataError*/] = useDocumentDataOnce(appContext.db.doc('announcements'));
  let [bulletin, isLoading, error] = useCollectionDataOnce(appContext.db
    .doc('announcements')
    .collection('items')
    .where('onBulletin', '==', true)
  );
  if (bulletin) {
    bulletin = filterAnnouncements(bulletin, date);
  }

  const changeDateClick = () => {
    setIsOpen(true);
    setOldDate(date);
  }

  const changeDate = () => {
    let ds = document.getElementById('date').value
    if (ds !== '') {
      let date = [
        ds.substring(5, 7),
        ds.substring(8, 10),
        ds.substring(0, 4)
      ]
      setDateError(false);
      setDate(new Date(date.join('/')))
      setIsOpen(false);
    } else {
      setDateError(true);
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const cancel = () => {
    setIsOpen(false);
    setDate(oldDate);
  }

  const popupsBlockedToggle = () => {
    setPopupBlockedModal(!popupBlockedModal);
  }

  const print = () => {
    let doc;
    if (bulletin.length === 0) {
      doc = `<h2 class="school-title">Patrick Henry High School Bulletin for ${format(this.state.date, 'EEEE MMMM d, yyyy')}</h2><h4 class="category-item text-center">There are no bulletin items for today</h4>`;
    } else {
      let byGroups = R.groupBy(it => it.bulletinCategory, bulletin);
      byGroups = R.keys(byGroups).sort((a, b) => a < b ? -1 : 1).map(key => {
        return {
          category: `<h3 class="category-name">${appContext.system.bulletinCategories[key].toUpperCase()}</h3>`,
          items: byGroups[key].map(it => {
            return `<h4 class="category-item"><span class="category-item-tease">${it.tease.toUpperCase()}</span>: ${it.itemText} ${it.contact} at ${it.contactEmail} ${format(it.dateOn, 'MMM d')}-${format(it.dateOff, 'MMM d')}</h4><hr />`;
          })
        }
      })
      byGroups = byGroups.map(g => R.concat([g.category], g.items));
      byGroups = byGroups.reduce((acc, g) => R.concat(acc, g), []);
      byGroups = R.prepend(`<h2 class="school-title">Patrick Henry High School Bulletin for ${format(date, 'EEEE MMMM d yyyy')}</h2>`, byGroups)
      doc = byGroups.join('');
    }
    let css = `
      .text-center {text-align: center;}
      .category-name {text-align: center; font-weight: bold; font-size: 1.2em;}
      .category-item {}
      .category-item-tease {font-weight: bold;}
      .school-title {text-align: center;}
      h1 {font-size: 2em;}
      h2 {font-size: 1.5em;}
      h3 {font-size: 1.17em;}
      h4 {font-size: 1em;}
      h5 {font-size: .83em;}
      h6 {font-size: .67em;}
      p {display: inline;}
    `;
    let html = `<!doctype html><html lang="en"><head><style>${css}</style></head><body>${doc}</body></html>`;
    try {
      let myWindow = window.open('', 'Print', 'height=600,width=800');
      myWindow.document.write(html);
      myWindow.document.close();
      myWindow.focus();
      myWindow.print();
    } catch(err) {
      setPopupBlockedModal(true);
    }
  }

  if (isLoading || !appContext.system) {
    return <div><List /><List /></div>
  }
  if (error) {
    return ItemsError('bulletin');
  } else {
    bulletin = bulletin.filter(it => !props.homepage || it.locationPriority === 'bulletin');
    let byGroups = R.groupBy(it => it.bulletinCategory, bulletin);
    byGroups = R.keys(byGroups).sort((a,b) => a < b ? -1 : 1).map(key => {
      return {
        category: appContext.system.bulletinCategories[key],
        items: byGroups[key]
      }
    });
    return (
      <div className="row bulletin">
        <div className="col">
          <div className="card">
            <div className="card-header text-center">
              <div className="row bulletin-date">
                <div className="col-lg-8">
                  DAILY BULLETIN FOR {format(date, "EEEE, MMMM d, yyyy").toUpperCase()}
                </div>
                <div className="col-lg-4">
                  <button type="button" className="btn btn-primary btn-sm mr-3" onClick={changeDateClick}>Change date</button>
                  <Link className="bulletin-link d-none d-lg-inline d-xl-inline" to="/web-bulletin">
                    <button type="button" className="btn btn-primary btn-sm mr-3">Web</button>
                  </Link>
                  <button type="button" className="btn btn-primary btn-sm d-none d-lg-inline d-xl-inline" onClick={print}>Print</button>
                </div>
              </div>
            </div>
            <div className="card-body">
              {
                bulletin.length === 0 ?
                  <h4 className="text-center">There are no bulletin items for today</h4> :
                  BulletinGroup(byGroups)
              }
            </div>
            <div className="card-footer">
              <h6 className="last-updated">Last updated {format(new Date(metadata.lastUpdated), 'MMM d')}</h6>
            </div>
          </div>
        </div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Change date</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label md={4} for="newDate">New date:</Label>
                <div className="col-md-8">
                  <Input required type="date" id="date" defaultValue={format(date, 'yyyy-MM-dd')}  className={(dateError ? 'bulletin-date-error' : '')} />
                </div>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={changeDate}>OK</button>{' '}
            <button className="btn btn-secondary" onClick={cancel}>Cancel</button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={popupBlockedModal} toggle={popupsBlockedToggle}>
          <ModalHeader>Popups are blocked!</ModalHeader>
          <ModalBody>You must unblock popup windows in your browser settings to print the bulletin</ModalBody>
        </Modal>
      </div>
    )
  }
}

export default Bulletin;
