import React, { useState, useContext, useRef } from 'react';
import { List } from "react-content-loader";
import { Form, Input } from 'reactstrap';
import AppContext from "../../AppContext";
import { useDocumentDataOnce, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import format from 'date-fns/format';
const R = require('ramda');

const Directory = () => {

  const appContext = useContext(AppContext);
  const [selected, setSelected] = useState(null);
  const [filterText, setFilterText] = useState('');

  const [metadata/*, metadataLoading, metadataError*/] = useDocumentDataOnce(appContext.db.doc('staffDirectory'));
  const [list, loading/*, error*/] = useCollectionDataOnce(appContext.db.doc('staffDirectory').collection('items'));

  const listing = useRef(null);

  const filterAndSort = (list, filterText) => {
    let periodsToCheck = R.range(1, 10).map(p => 'per'+p);
    let fieldsToCheck = R.concat(['lastName','firstName','email','department'], periodsToCheck);
    let filteredData = list.filter(it => {
      let results = R.map(field => {
        return it[field].toLowerCase().indexOf(filterText) >= 0
      }, fieldsToCheck);
      return R.any(bool => bool, results);
    });
    filteredData = filteredData.sort((a,b) => a.lastName+' '+a.firstName < b.lastName+' '+b.firstName ? -1 : 1);
    return filteredData
  }

  let filteredList = null;
  if (list) {
    Object.keys(list).forEach(idx => {
      list[idx].id = idx;
    })
    filteredList = filterAndSort(list, filterText)
  }

  const staffMemberSelected = evt => {
    listing.current.scrollIntoView();
  }

  const onSelect = evt => {
    setSelected(list[evt.currentTarget.getAttribute('data-id')]);
    staffMemberSelected();
  }

  const onFilterChange = evt => {
    setFilterText(evt.currentTarget.value.toLowerCase());
  }

  const clearFilter = () => {
    setFilterText('');
  }

  let periods, scheduleExists = false, s = selected;
  if (s) {
    periods = R.range(1, 10).map(per => s['per'+per] ? 'Per '+per+': '+s['per'+per] : null);
    scheduleExists = R.any(per => per !== null, periods);
  }

  const getHtml = () => {
    if (loading) {
      return <><List/><List/></>
    } else if (filteredList.length === 0) {
      return <p className="text-center">There are no directory entries to display</p>
    } else {
      return (
        <>
          <div className="staff-directory-list staff-directory-list-scroll d-none d-lg-block d-xl-block">
            <table className="table table-bordered table-hover table-sm">
              <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              {
                filteredList.map(item => (
                  <tr key={'sm'+item.id} onClick={onSelect} data-id={item.id}>
                    <td className="">{item.lastName}, {item.firstName}</td>
                    <td>{item.department}</td>
                    <td>{item.email}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
          <div className="staff-directory-list d-lg-none d-xl-none">
            <table className="table table-bordered table-hover table-sm">
              <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              {
                filteredList.map(item => (
                  <tr key={'sm'+item.id} onClick={onSelect} data-id={item.id}>
                    <td className="">{item.lastName}, {item.firstName}</td>
                    <td>{item.department}</td>
                    <td>{item.email}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>
        </>
      )
    }
  }

  return (
    <div className="staff-directory row">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <Form>
              <div className="form-group row">
                <label htmlFor="filterText" className="label col-lg-2 col-md-3 col-sm-4 col-form-label text-right">Filter:</label>
                <div className="col-lg-8 col-md-7 col-sm-6">
                  <Input type="text" id="filterText" placeholder="Search on name, department or class" value={filterText} onChange={onFilterChange}/>
                </div>
                <button className="btn btn-secondary btn-sm col-1" type="button" onClick={clearFilter}>X</button>
              </div>
            </Form>
          </div>
          <div className="card-body">
            {getHtml()}
          </div>
          <div className="card-footer">
            Page last updated: {metadata ? format(new Date(metadata.lastUpdated), 'MMM d, yyyy') : ''}
          </div>
        </div>
      </div>
      <div className="col-lg-6" ref={listing}>
        {
          s ?
            <div className="card staff-directory-entry">
              <div className="card-header">
                <p>{s.firstName} {s.lastName}</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    {
                      s && s.department ?
                        <p><b>Department</b>: {s.department}</p> : null
                    }
                    {
                      (s && s.room) ?
                        <p className=""><b>Room</b>: {s.room}</p> : null
                    }
                    {
                      (s && s.extension) ?
                        <p><b>Phone</b>: {appContext.system.information.SCHOOL_PHONE} ext {s.extension}</p> :
                        null
                    }
                    <p><b>Email</b>: <a href={"mailto:"+s.email}>{s.email}</a></p>
                    {
                      (s && s.webpage) ?
                        <p><b>Webpage</b>: <a target="_blank" rel="noopener noreferrer" href={s.webpage}>Click here</a></p>
                        : null
                    }
                    {
                      scheduleExists ?
                        <div>
                          <p><b>Schedule</b>:</p>
                          <div>
                            {
                              periods.map((p, idx) => (
                                <h6 className="staff-directory-schedule" key={'per'+idx}>{p}</h6>
                              ))
                            }
                          </div>
                        </div>
                        : null
                    }
                  </div>
                  <div className="col-md-4">
                    {
                      s.pictureUrl.length > 0 ?
                        <img src={s.pictureUrl} alt={'Picture of '+s.firstName+' '+s.lastName}/> :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="card">
              <div className="card-header">
                <p>Select a staff member</p>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default Directory;
