import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app'
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = require('./firebaseConfig');
const { SCHOOL } = require('./constants');

firebase.initializeApp(firebaseConfig);
const analytics = null; /*firebase.analytics();*/
const db = firebase.firestore().collection(SCHOOL);

ReactDOM.render(<App db={db} SCHOOL={SCHOOL} analytics={analytics} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
